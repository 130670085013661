import axios from "./axios";

export const sendIpgReq = async (ipgRequestData, token) => {
    return axios.post(`/api/v1/ipg/request`, ipgRequestData,
        {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',

            }
        }
    )
}


export const verifyPeyment = (authority, status) => {
    return axios.get(`/api/v1/ipg/verify/${authority}?status=${status}`);
};