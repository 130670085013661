import React, {useEffect, useState} from 'react';
import classes from './PaymentResult.module.css';
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {verifyPeyment} from "../../../../api/ipg";
import useAuth from "../../../../Hooks/useAuth";
import toast from "react-hot-toast";
import i18next from "i18next";
import Loading from "../../../../components/Loading/Loading";
import {useGetUserWallet} from "../../../../query";
import {images} from "../../../../assets/images/images";
import * as RoutesName from "../../../../Routes/routes";
import Button from "../../../../components/Button/Button";

const PaymentResult = () => {


    const {auth} = useAuth();
    let navigate = useNavigate();

    const {t} = useTranslation();
    const params = new URLSearchParams(useLocation().search);

    const authority = params.get("Authority");
    const status = params.get("Status");

    const [loading, setLoading] = useState(true);
    const [verifyStatus, setVerifyStatus] = useState(null);


    const {refetch:refetchUserWallet} = useGetUserWallet()


    const verifyPeymentFunc = async () => {
        setLoading(true)
       await verifyPeyment(authority,status)
           .then(async (res) => {
               setVerifyStatus("OK")
               refetchUserWallet()
           }).catch(err => {
               setVerifyStatus("NOK")
               /*if (i18next.exists('ErrorMessages.'+ err?.response?.data?.message)) {
                   toast.error(t("ErrorMessages."+ err?.response?.data?.message))
               }
               else {
                   toast.error(t("serverError"))
               }*/
           }).finally(() => {
               setLoading(false)
           })
    }

    useEffect(() => {
        if (status && authority) {
            verifyPeymentFunc();
        } else {
            navigate(RoutesName.Home);
        }
    }, [status, authority]);


    const content = () => {

        if (loading) return <div className={`column jc-center ai-center `}>
            <Loading type="spinner"/>
            <span className={`flashit mt-2`}>{t("loading")}</span>
        </div>


        if (verifyStatus === "OK") return <div className={`width-100 column jc-center ai-center`}>
            <img src={images.Approved} className={`${classes.image}`} alt=""/>
            <span className={`mt-2 mb-4 font-weight-bold`}>{t("PaymentResult.success")}</span>
        </div>

        if (verifyStatus === "NOK") return <div className={`width-100 column jc-center ai-center`}>
            <img src={images.Rejected} className={`${classes.image}`} alt=""/>
            <span className={`mt-2 mb-4 font-weight-bold`}>{t("PaymentResult.error")}</span>

            <Button
                type="button"
                buttonClass={`${classes.thisButton} cursor-pointer rounded-100-p width-60 ${classes.background} `}
                buttonTitle={t("PaymentResult.verifyTransactionAgain")}
                onClick={()=>verifyPeymentFunc()}
            />
        </div>

        return null;

    }



    return (
        <div className={`${classes.container} height-100 column jc-around ai-center py-3 px-5`}>

            {content()}

            { !loading && <div  className={`column width-60`}>


                <Button
                    type="button"
                    buttonClass={`${classes.thisButton} cursor-pointer rounded-100-p my-1 ${classes.border} `}
                    buttonTitle={t("History.depositHistory")}
                    onClick={()=> {navigate(RoutesName.DepositHistory)}}
                />

                <Button
                    type="button"
                    buttonClass={`${classes.thisButton} cursor-pointer rounded-100-p my-1 ${classes.border} `}
                    buttonTitle={t("home")}
                    onClick={()=> {navigate(RoutesName.Home)}}
                />

            </div>}

        </div>
    );
};

export default PaymentResult;
